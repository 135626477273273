<template>
  <vx-card>
    <div :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tgl. Pengajuan *</label>
          <flat-pickr class="w-full" v-model="data.tgl_pengajuan"></flat-pickr>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tgl. Mulai Publish *</label>
          <flat-pickr class="w-full" :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}" v-model="data.tgl_mulai_publish"></flat-pickr>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tipe *</label>
          <vs-input class="w-full" :value="data.tipe_platform" disabled/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Proyek *</label>
          <vs-input class="w-full" :value="data.nama_proyek" disabled/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Keterangan Pengajuan</label>
          <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan"/>
        </div>
      </div>

      <vs-divider class="mt-base">Detail Media</vs-divider>
      <div :class="{'opacity-50 pointer-events-none': !data.tipe_platform || !data.id_proyek}">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No Media *</label>
            <div @click="modalMedia.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih Media" :value="data.media.no_media" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Platform</label>
            <vs-input class="w-full" :value="data.media.nama_platform" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">File</label>
            <div>
              <vx-input-group>
                <vs-input :value="data.media.file_url_list ? (data.media.file_url_list.length + ' File') : null" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-image" :disabled="!data.media.file_url_list || data.media.file_url_list.length < 1" @click="showGallery(data.media.file_url_list)">Preview</vs-button>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Judul</label>
            <vs-input class="w-full" :value="data.media.judul" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Deskripsi</label>
            <vs-textarea class="w-full" v-model="data.media.deskripsi" readonly/>
          </div>
        </div>
      </div>

      <template v-if="data.tipe_platform === 'ADVERTISING'">
        <vs-divider class="mt-base">Detail Advertising</vs-divider>
        <div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-6/12 w-full">
              <label class="ml-1 text-xs">Tgl. Selesai Publish *</label>
              <flat-pickr class="w-full" v-model="data.tgl_selesai_publish"></flat-pickr>
            </div>
            <div class="vx-col sm:w-6/12 w-full">
              <label class="ml-1 text-xs">Lokasi *</label>
              <v-select multiple :closeOnSelect="false" :options="listLokasi" v-model="data.lokasi"/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-6/12 w-full">
              <label class="ml-1 text-xs">Biaya *</label>
              <v-money class="w-full" v-model="data.biaya" placeholder="Rp"/>
            </div>
            <div class="vx-col sm:w-6/12 w-full">
              <label class="ml-1 text-xs">Sisa Saldo *</label>
              <v-money class="w-full" v-model="data.sisa_saldo" placeholder="Rp"/>
            </div>
          </div>
        </div>
      </template>

      <template v-if="data.tipe_platform === 'BROADCAST'">
        <vs-divider class="mt-10">Broadcast Recipients</vs-divider>
        <div>
          <div class="vx-row mb-3">
            <div class="vx-col w-full">
              <div class="flex flex-wrap justify-between items-center mb-1">
                <div class="flex items-center space-x-2">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3">Add</vs-button>
                  <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.recipients.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
                </div>
                <div class="flex items-center">
                  <p class="text-sm">{{ data.recipients.length }} Data</p>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col w-full">
              <div class="w-full border border-solid d-theme-border-grey-light h-screen-50 overflow-y-auto">
                <table class="w-full table-fixed border-collapse">
                  <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-10">#</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-12">Aksi</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Nama</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Email</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Telp</th>
                    </tr>
                  </thead>
                  <tbody class="font-medium">
                    <tr v-for="(item, index) in data.recipients" :key="index" class="cursor-pointer hover:font-medium">
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ index + 1 }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap"><vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/></td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap"><vs-input class="w-full" type="text" v-model="item.nama"/></td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"><vs-input class="w-full" type="text" v-model="item.email" :disabled="isPlatformWhatsapp"/></td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"><vs-input class="w-full" type="text" v-model="item.telp" :disabled="isPlatformEmail" :danger="isPlatformWhatsapp && !isValidWhatsappNumber(item.telp)"/></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="vx-row mt-6">
        <div class="vx-col w-full">
          <div class="flex justify-end">
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <!--modals -->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Media"
              :active="modalMedia.active"
              v-on:update:active="modalMedia.active = $event">
      <Media :selectable="true" :disable-ids="[data.id_media]" :externalFilter="filterMedia" @selected="onSelectedModalMedia"/>
    </vs-popup>
  </vx-card>
</template>

<script>
import PengajuanMediaRepository from '@/repositories/marketing/pengajuan-media-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
import VMoney from '@/views/components/v-money/VMoney'
import Media from '@/views/pages/marketing/media/Media'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import _ from 'lodash'
import lokasiIndonesia from '@/assets/data/daerah-indonesia.json'
import { isValidWhatsappNumber } from '@/utilities/common/global-methods'

export default {
  name: 'PengajuanMediaEdit',
  components: {
    VMoney,
    Media,
    ValidationErrors,
    flatPickr,
    'v-select': vSelect
  },
  mounted () {
    this.getInitData()
  },
  computed: {
    filterMedia () {
      return {
        status: 'UNUSED',
        tipe_platform: this.data.tipe_platform,
        id_proyek: this.data.id_proyek
      }
    },
    listLokasi () {
      return _.flatten(_.map(lokasiIndonesia, item => {
        return [item.provinsi, ...item.kota]
      }))
    },
    isPlatformWhatsapp () {
      return this.data.media.nama_platform === 'WhatsApp'
    },
    isPlatformEmail () {
      return this.data.media.nama_platform === 'Email'
    }
  },
  data () {
    return {
      isLoadingInitData: false,
      isLoading: false,
      errors: null,
      modalMedia: {
        active: false
      },
      data: {
        tgl_pengajuan: moment().format('YYYY-MM-DD'),
        media: {},
        lokasi: [],
        recipients: []
      },
      deletedIds: []
    }
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true

      const idPengajuanMedia = this.$route.params.idPengajuanMedia
      PengajuanMediaRepository.show(idPengajuanMedia)
        .then(response => {
          this.data = this.buildInitData(response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    buildInitData (data) {
      return {
        id: data.header.id,
        tgl_pengajuan: data.header.tgl_pengajuan,
        tgl_mulai_publish: data.header.tgl_mulai_publish,
        tipe_platform: data.header.tipe_platform,
        id_proyek: data.header.id_proyek,
        nama_proyek: data.header.nama_proyek,
        keterangan: data.header.keterangan,
        tgl_selesai_publish: moment(data.header.tgl_selesai_publish).format('YYYY-MM-DD'),
        lokasi: data.header.lokasi_list,
        biaya: data.header.biaya,
        sisa_saldo: data.header.sisa_saldo,
        media: {
          ...data.header.media,
          file_url_list: data.header.media.files_url
        },
        recipients: _.map(data.recipients, item => {
          item.uuid = uuid()
          return item
        })
      }
    },

    addRow () {
      const row = { uuid: uuid() }
      this.data.recipients.unshift(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.recipients, item => item.uuid === uuid)
      const item = this.data.recipients[index]
      if (item.id) {
        this.deletedIds.push(item.id)
      }
      this.data.recipients.splice(index, 1)
    },

    clearRows () {
      this.$vs.dialog({
        ...this.confirmOptionsClearRows(),
        accept: () => {
          this.data.recipients = []
        }
      })
    },

    confirmOptionsClearRows () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Semua data recipients akan dihapus?',
        acceptText: 'Ya',
        cancelText: 'Batal'
      }
    },

    onSelectedModalMedia (data) {
      this.data.media = data
      this.modalMedia.active = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      PengajuanMediaRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      let params = {
        id: this.data.id,
        id_proyek: this.data.id_proyek,
        id_media: this.data.media.id,
        tipe_platform: this.data.tipe_platform,
        tgl_pengajuan: this.data.tgl_pengajuan,
        keterangan: this.data.keterangan,
        tgl_mulai_publish: this.data.tgl_mulai_publish,
        deletedIds: this.deletedIds
      }

      if (this.data.tipe_platform === 'ADVERTISING') {
        params = {
          ...params,
          tgl_selesai_publish: this.data.tgl_selesai_publish,
          biaya: this.data.biaya,
          jumlah: this.data.jumlah,
          sisa_saldo: this.data.sisa_saldo,
          lokasi: this.data.lokasi
        }
      }

      if (this.data.tipe_platform === 'BROADCAST') {
        params = {
          ...params,
          recipients: _.map(this.data.recipients, item => _.pick(item, ['id', 'nama', 'email', 'telp']))
        }
      }

      return params
    },

    isValidWhatsappNumber (val) {
      return isValidWhatsappNumber(val)
    },

    showGallery (listMedia) {
      const lightbox = GLightbox()
      const elements = _.map(listMedia, item => ({ href: item }))
      lightbox.setElements(elements)
      lightbox.open()
    },

    onSuccess () {
      this.$router.push({ name: 'marketing.pengajuanMedia' })
      this.notifySuccess('Data berhasil disimpan.')
    }
  }
}
</script>
